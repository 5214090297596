import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormInputs, initAdminPermissions, schema } from "./constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { db, functions } from "src/firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormModeTypes } from "src/types/generic";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { httpsCallable } from "firebase/functions";
import { IEmployeeBodyData } from "src/types/employees";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useGetLocationsList } from "src/api/locations";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddEditEmployeePage = () => {
  const { id } = useParams();
  const mode: FormModeTypes = !id ? "add" : "edit";
  const navigate = useNavigate();
  const location = useLocation();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [adminPermissions, setAdminPermissions] =
    useState(initAdminPermissions);
  const [fullAdminPermissions, setFullAdminPermissions] = useState(false);
  const [hasAdminRole, setHasAdminRole] = useState(false);
  const [hasOperatorRole, setHasOperatorRole] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [employeeData, setEmployeeData] = useState<any>();
  const [operatorType, setOperatorType] = useState("Location Manager");
  const [isLoadingFetching, setIsLoadingFetching] = useState(
    true && mode === "edit"
  );
  const [isLoading, setIsLoading] = useState(false);

  const isComingFromActiveEmployeesPage = location.pathname.includes("active");

  const breadcrumbs = [
    <Link
      color={"#A1A5B7"}
      component={RouterLink}
      underline="hover"
      to={
        isComingFromActiveEmployeesPage
          ? "/employees/active"
          : "/employees/archived"
      }
    >
      {isComingFromActiveEmployeesPage ? "Active" : "Archived"} Employees
    </Link>,

    <Typography key="3" color="#A1A5B7">
      {mode === "add" ? "Add Employee" : "Update Employee"}
    </Typography>,
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  //Callable functions
  const addEmployee = httpsCallable(functions, "createEmployee");
  const updateEmployee = httpsCallable(functions, "updateEmployee");
  const unarchiveEmployee = httpsCallable(functions, "unarchiveEmployee");

  // APIS
  const { data: locationsData } = useGetLocationsList({ type: "active" });

  const handleCheckboxChange = (
    moduleIndex: number,
    permissionIndex: number
  ) => {
    setAdminPermissions((prev) => {
      const updatedPermissions = [...prev];
      updatedPermissions[moduleIndex].permissions[permissionIndex].checked =
        !updatedPermissions[moduleIndex].permissions[permissionIndex].checked;
      return updatedPermissions;
    });
  };

  // Function to handle full admin permission toggle
  const handleFullAdminPermission = () => {
    if (!fullAdminPermissions) {
      setAdminPermissions((prev) => {
        const updatedPermissions = [...prev];
        updatedPermissions.forEach((module) => {
          module.permissions.forEach((permission) => {
            permission.checked = true;
          });
        });
        return updatedPermissions;
      });
    } else {
      setAdminPermissions((prev) => {
        const updatedPermissions = [...prev];
        updatedPermissions.forEach((module) => {
          module.permissions.forEach((permission) => {
            permission.checked = false;
          });
        });
        return updatedPermissions;
      });
    }

    setFullAdminPermissions((prev) => !prev);
  };

  // Function to check if all checked keys are true
  const areAllCheckedTrue = () => {
    for (const item of adminPermissions) {
      for (const permission of item.permissions) {
        if (!permission.checked) {
          return false;
        }
      }
    }
    // If all checked keys are true, return true
    return true;
  };

  const handleChangeLocations2 = (value: any[]) => {
    setSelectedLocations(value);
  };

  const handleAddEmployee = (data: IEmployeeBodyData) => {
    addEmployee(data)
      .then(() => {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "The employee has been added successfully!",
          },
        ]);
        setIsLoading(false);
        navigate("/employees/active");
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleUpdateEmployee = (data: IEmployeeBodyData) => {
    updateEmployee(data)
      .then(() => {
        if (employeeData?.isUserActive === false) {
          handleUnarchiveEmployee();
          return;
        }
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "The employee has been updated successfully!",
          },
        ]);
        setIsLoading(false);
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const handleUnarchiveEmployee = () => {
    unarchiveEmployee({ employeeId: id })
      .then(() => {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: "The employee has been activated successfully!",
          },
        ]);
        setIsLoading(false);
        navigate("/employees/active");
      })
      .catch((error) => {
        const { message, details } = error;
        setIsLoading(false);
        setNotifications([
          ...notifications,
          {
            type: "error",
            message:
              details?.message || message || "Oops! Something went wrong.",
          },
        ]);
      });
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // validations
    if (!hasAdminRole && !hasOperatorRole) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Choose at least one role",
        },
      ]);
      return;
    }
    const isAtLeastOneChecked = adminPermissions.some((module) => {
      return module.permissions.some((permission) => permission.checked);
    });
    if (hasAdminRole && !isAtLeastOneChecked) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Select permissions for admin role",
        },
      ]);
      return;
    }
    if (hasOperatorRole && selectedLocations.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Select operator locations",
        },
      ]);
      return;
    }

    const adminPermissionsToSend = adminPermissions.reduce((acc: any, item) => {
      const permissionsObj: any = {};
      item.permissions.forEach((permission) => {
        permissionsObj[permission.id] = permission.checked;
      });
      acc[item.name] = permissionsObj;
      return acc;
    }, {});

    const dataToSend = {
      name: data.name,
      arabicName: data.arabicName,
      email: data.email,
      phone: data.phone,
      nationalId: data.nationalId,
      roles: [
        ...(hasAdminRole ? ["Admin"] : []),
        ...(hasOperatorRole ? ["Operator"] : []),
      ],
      ...(hasAdminRole ? { permissions: adminPermissionsToSend } : {}),
      ...(hasOperatorRole ? { locationType: operatorType } : {}),
      ...(hasOperatorRole
        ? { allowedLocations: selectedLocations.map((loc) => loc.nameEn) }
        : {}),
    };

    setIsLoading(true);
    if (mode === "add") {
      handleAddEmployee(dataToSend);
    } else {
      handleUpdateEmployee({ ...dataToSend, employeeId: id });
    }
  };

  //Fetch locations
  useEffect(() => {
    let unsubscribe: () => void;
    unsubscribe = onSnapshot(
      query(collection(db, "locations")),
      (QuerySnapshot) => {
        const fetchedLocations: any[] = [];
        QuerySnapshot.forEach((doc) => {
          fetchedLocations.push({ ...doc.data(), id: doc.id });
        });
        // Sort locations alphabetically by name
        fetchedLocations.sort((a, b) => a.name?.localeCompare(b.name));
        setLocations(fetchedLocations);
      }
    );
    // Return the cleanup function to unsubscribe when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  //Fetch Employee data
  useEffect(() => {
    if (id) {
      let unsubscribe: () => void;
      const docRef = doc(db, "users", `${id}`);

      unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = { ...doc.data() };
          console.log("user to edit:", { data });
          setEmployeeData(data);
          setIsLoadingFetching(false);
          // Reset the form and permissions with employee data
          reset({
            name: data.name,
            arabicName: data.arabicName,
            email: data.email,
            nationalId: data.nationalId,
            phone: data.phone,
          });
          setHasAdminRole(data.roles.includes("Admin"));
          setHasOperatorRole(data.roles.includes("Operator"));

          if (data.roles.includes("Admin")) {
            const adminPermissionsData = initAdminPermissions.map(
              (category) => {
                const permissions = category.permissions.map((permission) => {
                  return {
                    ...permission,
                    checked: data.permissions?.[category.name]?.[permission.id],
                  };
                });
                return {
                  ...category,
                  permissions: permissions,
                };
              }
            );

            setAdminPermissions(adminPermissionsData);
            console.log({ adminPermissionsData });
          }

          if (data.roles.includes("Operator")) {
            setOperatorType(
              data.isLocationManager ? "Location Manager" : "Operator"
            );
            const selectedLocationsArr = data.allowedLocations.map(
              (locName: string) =>
                locations.find((location) => location.nameEn === locName)
            );
            console.log({ selectedLocationsArr });
            setSelectedLocations(selectedLocationsArr);
          }
        } else {
          console.log("No such document!");
          setNotifications([
            ...notifications,
            {
              type: "error",
              message: "No such employee!",
            },
          ]);
        }
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [id, locations]);

  // Effect to update fullAdminPermissions when permissions change
  useEffect(() => {
    const allChecked = areAllCheckedTrue();
    if (!allChecked) {
      setFullAdminPermissions(false);
    } else {
      setFullAdminPermissions(true);
    }
  }, [adminPermissions]);

  if (isLoadingFetching) {
    return (
      <Box
        height={"80vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={"30px"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography fontSize={"22px"} mb={1} fontWeight={600}>
          {mode === "add" ? "Add New Employee" : "Update Employee"}
        </Typography>
        {/* <RouterBreadcrumbs /> */}
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: "#5E6278" }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        {/* Employee info */}
        <Box mt={3} p={4} border={"1px solid #5B5B5B"} borderRadius={"10px"}>
          <Typography mb={3}>Employee info</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name in English"
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="arabicName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name in Arabic"
                    {...field}
                    error={!!errors.arabicName}
                    helperText={errors.arabicName?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="nationalId"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="National ID"
                    {...field}
                    error={!!errors.nationalId}
                    helperText={errors.nationalId?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Email"
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Mobile Number"
                    {...field}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: field.value !== undefined ? true : false,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Accessibility */}
        <Box mt={3} p={4} border={"1px solid #5B5B5B"} borderRadius={"10px"}>
          <Typography mb={3}>Accessibility</Typography>
          {/* Admin Role */}
          <Box mt={3} p={3} border={"1px solid #5B5B5B"} borderRadius={"10px"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasAdminRole}
                  onChange={(e) => setHasAdminRole(e.target.checked)}
                />
              }
              label="Admin Role"
            />
            {hasAdminRole && (
              <>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Box
                    bgcolor={"primary.main"}
                    borderRadius={"12px"}
                    height={"30px"}
                    width={"3px"}
                  />
                  <Checkbox
                    checked={fullAdminPermissions}
                    onChange={handleFullAdminPermission}
                  />
                  <Box>
                    <Typography fontSize={"14px"} fontWeight={600}>
                      Full Permission
                    </Typography>
                    <Typography
                      color={"#A1A5B7"}
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      Accessibility in all dashboard modules
                    </Typography>
                  </Box>
                </Stack>
                <Grid container spacing={3} mt={2}>
                  {adminPermissions.map((module, moduleIndex) => {
                    return (
                      <Grid item xs={12} sm={6} lg={3} key={module.name}>
                        <Stack
                          height={"100%"}
                          border={"1px solid #5B5B5B"}
                          p={3}
                          borderRadius={"10px"}
                        >
                          <Typography
                            fontWeight={600}
                            textTransform={"capitalize"}
                          >
                            {module.label}
                          </Typography>
                          {module.permissions.map(
                            (permission, permissionIndex) => {
                              return (
                                <FormControlLabel
                                  key={permission.id}
                                  control={
                                    <Checkbox
                                      checked={permission.checked}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          moduleIndex,
                                          permissionIndex
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography style={{ fontSize: "13px" }}>
                                      {permission.label}
                                    </Typography>
                                  }
                                />
                              );
                            }
                          )}
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </Box>
          {/* Operator Role */}
          <Box mt={3} p={3} border={"1px solid #5B5B5B"} borderRadius={"10px"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasOperatorRole}
                  onChange={(e) => setHasOperatorRole(e.target.checked)}
                />
              }
              label="Operator Role"
            />
            {hasOperatorRole && (
              <>
                <FormControl size="small" sx={{ display: "block", my: 2 }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={operatorType}
                    label="Age"
                    onChange={(e) => setOperatorType(e.target.value)}
                    sx={{ width: { xs: "100%", sm: "300px" } }}
                  >
                    <MenuItem value={"Location Manager"}>
                      Location Manager
                    </MenuItem>
                    <MenuItem value={"Operator"}>Operator</MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  size="small"
                  multiple
                  id="tags-outlined"
                  options={locationsData?.locations || []}
                  value={selectedLocations}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) => option?.nameEn}
                  renderOption={(props, option, { selected }) => (
                    <li key={props.id} {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, padding: "0" }}
                        checked={selected}
                      />
                      <Typography>{option.nameEn}</Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Locations"
                      placeholder="Locations"
                    />
                  )}
                  onChange={(event, value) => {
                    handleChangeLocations2(value);
                  }}
                />
              </>
            )}
          </Box>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          mt={"50px"}
          gap={3}
        >
          <Button
            variant="outlined"
            sx={{ minWidth: { xs: "100px", md: "208px" } }}
            onClick={() =>
              navigate(
                isComingFromActiveEmployeesPage
                  ? "/employees/active"
                  : "/employees/archived"
              )
            }
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: { xs: "100px", md: "208px" } }}
            loading={isLoading}
          >
            {employeeData?.isUserActive === false
              ? "Save and Unarchive"
              : "Save"}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default AddEditEmployeePage;
