import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import ClosIcon from "src/assets/svg-icons/close.svg";

export const schema = yup.object().shape({
  type: yup
    .string()
    .required("required")
    .oneOf(["valet", "selfParking"] as const),
  name: yup.string().required("Required"),
  payment: yup
    .string()
    .required("required")
    .oneOf(["entry", "exit", "flexible"] as const),
  pricingType: yup
    .string()
    .required("required")
    .oneOf(["free", "fixed", "custom", "hourly"] as const),
  price: yup.number().when("pricingType", {
    is: (pricing: string) => ["fixed", "custom", "hourly"].includes(pricing),
    then: (value) =>
      value
        .typeError("Required")
        .positive("Must be a positive number")
        .required("Required"),
  }),
  gracePeriod: yup.number().when(["pricingType", "payment"], {
    is: (pricingType: string, payment: string) =>
      ["fixed", "custom", "hourly"].includes(pricingType) && payment === "exit",
    then: (value) =>
      value
        .typeError("Required")
        .min(0, "Must be a positive number or zero")
        .required("Required"),
    // otherwise: (value) => value.notRequired(),
  }),

  startingHours: yup.number().when("pricingType", {
    is: "custom",
    then: (value) =>
      value
        .typeError("Required")
        .min(0, "Must be a positive number or zero")
        .required("Required"),
  }),
  priceOfExceedingHours: yup.number().when("pricingType", {
    is: "custom",
    then: (value) =>
      value
        .typeError("Required")
        .min(0, "Must be a positive number or zero")
        .required("Required"),
  }),
  pricingModal: yup
    .string()
    .required("required")
    .oneOf(["customByHours"] as const),
  exceedingPricingType: yup
    .string()
    .required("required")
    .oneOf(["hourly", "allHours"] as const),
  ticketViolationPrice: yup
    .number()
    .typeError("Required")
    .min(0, "Must be a positive number or zero"),
});

export interface ServiceFormInputs extends yup.InferType<typeof schema> {}

const StyledRadioGroup = styled(RadioGroup)({
  "& .MuiFormControlLabel-label": {
    fontSize: "12px", // Set your desired font size here
  },
});

const initValues = {
  type: "valet",
  pricingModal: "customByHours",
  exceedingPricingType: "hourly",
  pricingType: "free",
  //   payment: "",
};

interface IProps {
  open: boolean;
  onClose: any;
  serviceDataToEdit?: any;
  serviceDataToEditIndex?: number;
  setServices: Function;
  services: ServiceFormInputs[];
}

const AddEditServicePopup: React.FC<IProps> = (props) => {
  const {
    open,
    onClose,
    serviceDataToEdit,
    serviceDataToEditIndex,
    setServices,
    services,
  } = props;
  const mode = !serviceDataToEdit ? "add" : "edit";
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const methods = useForm<ServiceFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "valet",
      pricingModal: "customByHours",
      exceedingPricingType: "hourly",
      pricingType: "free",
      //   payment: "",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
    trigger,
    getValues,
  } = methods;

  const handleClose = () => {
    onClose();
  };

  const onSubmitService = async () => {
    const valid = await trigger(
      [
        "type",
        "name",
        "payment",
        "price",
        "pricingType",
        "exceedingPricingType",
        "gracePeriod",
        "priceOfExceedingHours",
        "pricingModal",
        "startingHours",
        "ticketViolationPrice",
      ],
      { shouldFocus: true }
    );
    if (!valid) return;
    const data = getValues();
    console.log({ data });
    // validate to have only one self-parking service
    const isSelfServiceAlreadyExists = !!services.find(
      (service, index) =>
        service.type === "selfParking" &&
        !(mode === "edit" && serviceDataToEditIndex === index)
    );
    if (isSelfServiceAlreadyExists && data.type === "selfParking") {
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          type: "error",
          message:
            "Sorry! This location already has a self-parking service, you can't create another one",
        },
      ]);
      return;
    }
    // validate naming; to have no repeated names for the same type (valet or self-parking)
    const selfServicesNames = services.map((service, index) => {
      if (
        (mode === "edit" && index === serviceDataToEditIndex) ||
        service.type === "valet"
      ) {
        return;
      }
      return service.name.trim().toLocaleLowerCase();
    });
    const valetServicesNames = services.map((service, index) => {
      if (
        (mode === "edit" && index === serviceDataToEditIndex) ||
        service.type === "selfParking"
      ) {
        return;
      }
      return service.name.trim().toLocaleLowerCase();
    });
    if (
      selfServicesNames.includes(data.name.trim().toLocaleLowerCase()) &&
      data.type === "selfParking"
    ) {
      console.log({ services });
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            "You have a self-parking service with the same name already!",
        },
      ]);
      return;
    }
    if (
      valetServicesNames.includes(data.name.trim().toLocaleLowerCase()) &&
      data.type === "valet"
    ) {
      console.log({ services });
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "You have a valet service with the same name already!",
        },
      ]);
      return;
    }

    if (mode === "add") {
      setServices((prev: any) => [...prev, data]);
    } else {
      setServices((prev: any) => {
        const dataUpdated = [...prev];
        dataUpdated[serviceDataToEditIndex as number] = data;
        return dataUpdated;
      });
    }
    handleClose();
    console.log({ data });
  };

  useEffect(() => {
    if (watch("type") === "valet") {
      setValue("payment", "flexible");
    } else {
      setValue("payment", "exit");
      if (watch("pricingType") === "custom" && watch("payment") === "entry") {
        setValue("pricingType", "free");
      }
    }
  }, [watch("type")]);

  useEffect(() => {
    if (watch("payment") === "entry" && watch("pricingType") === "custom") {
      setValue("pricingType", "free");
    }
  }, [watch("payment")]);

  useEffect(() => {
    console.log({ serviceDataToEdit });
    if (!!serviceDataToEdit) {
      console.log({ serviceDataToEdit });
      reset({ ...serviceDataToEdit });
    }
  }, [serviceDataToEdit]);

  console.log({ errors });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      component={"div"}
    >
      <DialogTitle sx={{ p: "22px 32px 25px 50px" }}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
            <img src={ClosIcon} alt="" />
          </IconButton>
        </Box>
        <Typography fontSize={"22px"} fontWeight={600} lineHeight={"22px"}>
          Service Configuration
        </Typography>
      </DialogTitle>
      <form>
        <Box px={"50px"}>
          {/* Service Type */}
          <Typography fontSize={"18px"} fontWeight={500}>
            Service Type
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.type}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="valet"
                    control={<Radio />}
                    label="Valet Service"
                  />
                  <FormControlLabel
                    value="selfParking"
                    control={<Radio />}
                    label="Self Parking"
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          <Stack direction={"row"} gap={2} mt={2}>
            <Box>
              {/* Service Name */}
              <Typography mb={2} fontSize={"18px"} fontWeight={500}>
                Service Name
              </Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="medium"
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Stack>

          {/* payment */}
          <Typography fontSize={"18px"} fontWeight={500} mt={"32px"}>
            Payment
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.payment}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="payment"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="entry"
                    control={<Radio />}
                    label="At Entry"
                    disabled={watch("type") === "valet"}
                  />
                  <FormControlLabel
                    value="exit"
                    control={<Radio />}
                    label="At Exit"
                    disabled={watch("type") === "valet"}
                  />
                  <FormControlLabel
                    value="flexible"
                    control={<Radio />}
                    label="Flexible (At Entry or At Exit)"
                    disabled={watch("type") === "selfParking"}
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          {/* pricing */}
          <Typography fontSize={"18px"} fontWeight={500} mt={"32px"}>
            Pricing
          </Typography>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.pricingType}
            sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
          >
            <Controller
              name="pricingType"
              control={control}
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  key={field.value}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="free"
                    control={<Radio />}
                    label="Free"
                  />
                  <FormControlLabel
                    value="fixed"
                    control={<Radio />}
                    label="Fixed price"
                  />
                  <FormControlLabel
                    value="hourly"
                    control={<Radio />}
                    label="Hourly"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom price"
                    disabled={
                      watch("type") === "selfParking" &&
                      watch("payment") === "entry"
                    }
                  />
                </StyledRadioGroup>
              )}
            />
          </FormControl>
          {/* fixed or hourly pricing */}
          {(watch("pricingType") === "fixed" ||
            watch("pricingType") === "hourly") && (
            <Stack>
              {watch("payment") === "exit" && (
                <Controller
                  name="gracePeriod"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.gracePeriod}
                      helperText={errors.gracePeriod?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Grace period in mins"
                    />
                  )}
                />
              )}
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="medium"
                    {...field}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                    fullWidth
                    sx={{ maxWidth: "212px", marginTop: "14px" }}
                    label="Price"
                  />
                )}
              />
            </Stack>
          )}
          {/* custom pricing */}
          {watch("pricingType") === "custom" && (
            <Box>
              {watch("payment") === "exit" && (
                <Controller
                  name="gracePeriod"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.gracePeriod}
                      helperText={errors.gracePeriod?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Grace period in mins"
                    />
                  )}
                />
              )}
              <Stack
                direction={"row"}
                gap={1}
                alignItems={"flex-start"}
                mt={2}
                flexWrap={"wrap"}
              >
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.pricingModal}
                  sx={{ maxWidth: "208px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Pricing Modal
                  </InputLabel>
                  <Controller
                    name="pricingModal"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Pricing Modal"
                        size="medium"
                      >
                        <MenuItem value={"customByHours"}>
                          Custom By Hours
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.pricingModal?.message}
                  </FormHelperText>
                </FormControl>
                <Controller
                  name="startingHours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.startingHours}
                      helperText={errors.startingHours?.message}
                      fullWidth
                      sx={{ maxWidth: "212px" }}
                      label="Starting Hours"
                    />
                  )}
                />
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.price}
                      helperText={errors.price?.message}
                      fullWidth
                      sx={{ maxWidth: "212px" }}
                      label="Price"
                    />
                  )}
                />
                <Controller
                  name="priceOfExceedingHours"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.priceOfExceedingHours}
                      helperText={errors.priceOfExceedingHours?.message}
                      fullWidth
                      sx={{ maxWidth: "212px" }}
                      label="Price For Exceeding Hours"
                    />
                  )}
                />
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"100%"}
                >
                  <Typography fontSize={"12px"} fontWeight={500}>
                    Exceeding Price Type
                  </Typography>
                  <FormControl
                    fullWidth
                    size="medium"
                    error={!!errors.exceedingPricingType}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                      mt: "12px",
                    }}
                  >
                    <Controller
                      name="exceedingPricingType"
                      control={control}
                      render={({ field }) => (
                        <StyledRadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="hourly"
                            control={
                              <Radio size="small" sx={{ padding: "0 9px" }} />
                            }
                            label="Per Hour"
                          />
                          <FormControlLabel
                            value="allHours"
                            control={
                              <Radio size="small" sx={{ padding: "0 9px" }} />
                            }
                            label="All Hours"
                          />
                        </StyledRadioGroup>
                      )}
                    />
                  </FormControl>
                </Box>
              </Stack>
              {watch("payment") === "exit" && (
                <Controller
                  name="ticketViolationPrice"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      size="medium"
                      {...field}
                      error={!!errors.ticketViolationPrice}
                      helperText={errors.ticketViolationPrice?.message}
                      fullWidth
                      sx={{ maxWidth: "212px", marginTop: "14px" }}
                      label="Ticket violation price"
                    />
                  )}
                />
              )}
            </Box>
          )}
        </Box>

        <DialogActions sx={{ padding: "30px 50px 50px 50px" }}>
          <Button
            sx={{ minWidth: "208px" }}
            variant="contained"
            onClick={onSubmitService}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEditServicePopup;
