import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface IProps {
  open: boolean;
  setOpen: Function;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  submitBtnLabel?: string;
  onSave: (value: string) => void;
  isLoading?: boolean;
  withNote?: boolean;
}
export default function ConfirmPopup(props: IProps) {
  const {
    open,
    setOpen,
    handleClose,
    title = "Are you sure?",
    subtitle,
    submitBtnLabel = "Save",
    onSave,
    isLoading = false,
    withNote = false,
  } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const note = formJson.note;
          onSave(note);
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: { xs: "100%", sm: "500px" } }}>
        <DialogContentText>
          <Typography color={"textPrimary"} mb={"20px"} fontSize={"14px"}>
            {subtitle}
          </Typography>
        </DialogContentText>
        {withNote && (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="note"
            placeholder="Leave your note..."
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            sx={{ mb: 1 }}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          p: "0 22px 22px 22px",
          gap: 1,
        }}
      >
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          {submitBtnLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
