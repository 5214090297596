import * as yup from "yup";

const englishRegex = /^[A-Za-z0-9\s\-]+$/; // Matches English letters, numbers, spaces, and dashes
const arabicRegex = /^[\u0600-\u06FF0-9\s\-]+$/; // Matches Arabic letters, numbers, spaces, and dashes

export const schema = yup.object().shape({
  //basic info
  mode: yup
    .string()
    .required("Required")
    .oneOf(["add", "edit"] as const),
  clientId: yup.string(),
  cityId: yup.string().required("Required"),
  nameEn: yup
    .string()
    .required("Required")
    .when("mode", {
      is: "add",
      then: (value) =>
        value.matches(
          englishRegex,
          "Name must contain only English letters, numbers, spaces, and dashes "
        ),
    }),
  nameAr: yup
    .string()
    .required("Required")
    .matches(
      arabicRegex,
      "Name must contain only Arabic letters, numbers, spaces, and dashes"
    ),
  locationAddress: yup.string().required("Required"),
  phoneNumber: yup.string(),
  //gates
  gates: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    )
    .required("Choose related payments is required"),
  totalParkingSpaces: yup
    .number()
    .typeError("Required")
    // .positive("Must be a positive number")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
  valetParkingSpaces: yup
    .number()
    .typeError("Required")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
  selfParkingSpaces: yup
    .number()
    .typeError("Required")
    .min(0, "Must be a positive number or zero")
    .required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}
